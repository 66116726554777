import React from 'react'
import { graphql, Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'

export const pageQuery = graphql`
  query recentPosts {
    allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {frontmatter: {section: {eq: "blog"}}}) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields { slug }
          frontmatter {
            date(formatString: "MMMM YYYY")
            #date(formatString: "MM/DD/YYYY")
            title
            section
            tags
          }
        }
      }
    }
  }
`

function IndexPage({ data }) {
  const { edges: recent } = data.allMdx
  return (
    <BasicLayout>
      <h1>Posts</h1>
      {
        recent.map(({ node }) => {
          const { date, title, section, tags } = node.frontmatter
          //if (title.length > 0 && tags !== null && !tags.includes("tutorial")) {
            if (title.length > 0 && !(tags.includes("expired") || (tags.includes("draft")))) {
              return (
                <div key={node.id}>
                  <h4><span>{date}</span>: <Link to={`/${node.fields.slug}`}>{title}</Link></h4>
                  {/* <p><i>{node.excerpt}</i></p> */}
                  <hr />
                </div>
              )
            } else if (title.length > 0 && tags.includes("expired")) {
              return (
                <div key={node.id}>
                  <h4><span>{date}</span>: <strike><Link to={`/${node.fields.slug}`}>{title}</Link></strike></h4>
                  {/* <p><i>{node.excerpt}</i></p> */}
                  <hr />
                </div>
              )
            }
        })
      }
    </BasicLayout>
  )
}


export default IndexPage